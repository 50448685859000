
import Vue, { PropType } from "vue";

import { DialogCloseType, DialogI } from "@/types/dialog";
import BaseDialog from "@/components/dialog/BaseDialog.vue";
import Btn from "@/components/v2/base/Btn.vue";
import TextareaField from "@/components/form/inputs/TextareaField.vue";
import { validator } from "@/utils/validators";
import { SnackbarType } from "@/types/snackbar";
import { InviteCompanyUsersStatus } from "@prestonly/preston-common";

export default Vue.extend({
  name: "InviteUsersToCompany",
  components: {
    TextareaField,
    BaseDialog,
    Btn,
  },

  data() {
    return {
      valid: false,
      closeOnly: false,
      existingList: [],
      userEmails: "",
    };
  },

  mixins: [validator],
  computed: {
    noOfInvitedUsers(): number {
      return (
        this.userEmails
          .split(",")
          .map((email) => email.trim())
          .filter(Boolean).length || 0
      );
    },
    isTooManyInvitations(): boolean {
      return this.dialog.config.payload.remaining - this.noOfInvitedUsers < 0;
    },
  },

  methods: {
    async submit() {
      const { invited, result } = await this.$store.dispatch("company/inviteUsersToCompany", {
        emailsList: this.userEmails.split(",").map((email) => email.trim()),
        companyId: this.dialog.config.payload.companyId,
        companyAccessCode: this.dialog.config.payload.companyAccessCode,
      });

      console.log(result);
      if (!invited) {
        await this.$store.dispatch(
          "snackbar/open",
          {
            config: {
              closeTimeout: 10000,
              type: SnackbarType.ERROR,
              message: this.$t("dialogs.inviteUsersToCompany.usersNotInvited"),
            },
          },
          { root: true }
        );
        return;
      }

      if (result.status === InviteCompanyUsersStatus.DENIED) {
        await this.$store.dispatch(
          "snackbar/open",
          {
            config: {
              closeTimeout: 10000,
              type: SnackbarType.ERROR,
              message: this.$t(`company.adminInterface.inviteUsers.${result.message}`),
            },
          },
          { root: true }
        );
        return;
      }
      if (result.status === InviteCompanyUsersStatus.PARTIAL_SUCCESS) {
        await this.$store.dispatch(
          "snackbar/open",
          {
            config: {
              closeTimeout: 10000,
              type: SnackbarType.WARN,
              message: this.$t(`company.adminInterface.inviteUsers.${result.message}`),
            },
          },
          { root: true }
        );
        this.closeOnly = true;
        this.userEmails = result.details.emailsList;
        return;
      }

      await this.$store.dispatch(
        "snackbar/open",
        {
          config: {
            type: SnackbarType.SUCCESS,
            message: this.$t("company.adminInterface.inviteUsers.success"),
          },
        },
        { root: true }
      );

      this.dialog.close({ type: DialogCloseType.SUBMITTED });
    },
    cancel() {
      this.dialog.close({ type: DialogCloseType.CLOSED });
    },
  },

  props: {
    dialog: {
      type: Object as PropType<DialogI>,
      required: true,
    },
  },
});
